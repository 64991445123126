<i18n lang="yaml">
pt:
  newVersionAlert: 'Uma nova versão do app está disponível. <br>Atualize para continuar usando.'
  update: 'Atualizar'
  newViewVersionAlert: 'Esta página recebeu alterações. <br>Atualize para ver as informações mais recentes.'
en:
  newVersionAlert: 'A new version of the app is available. <br>Update to continue using.'
  update: 'Update'
  newViewVersionAlert: 'This page has received changes. <br>Update to see the latest information.'
</i18n>
<template>
  <deck-snackbar
    ref="newVersionAlert"
    v-model="showAlert"
    bottom
    right
    icon="sync-alt"
    :timeout="-1"
    min-width="auto"
  >
    <p
      v-test-id="'alert-message'"
      class="mb-0"
      v-html="t(hasNewViewVersion ? 'newViewVersionAlert' : 'newVersionAlert')"
    />

    <template #action>
      <deck-button
        :text="t('update')"
        small
        @click="reload"
      />
    </template>
  </deck-snackbar>
</template>

<script>
import { setRootCssProp, clearRootCssProp, getElementEdgeDistancesToViewport } from '~/assets/javascript/utils/dom';
import DeckSnackbar from '~/deck/snackbar';
import DeckButton from '~/deck/button';
import { mapGetters } from '~/assets/javascript/modules/vuex';

const SHOW_ALERT_INTERVAL = 60 * 1000; // 1 minute

export default {
  name: 'NewVersionAlert',
  components: {
    DeckSnackbar,
    DeckButton,
  },
  setup() {
    return {
      t: useI18n().t,
      ...mapGetters('view', ['currentViewChangedAt']),
    };
  },
  data() {
    return {
      changeShowAlertThrottledCallback: _throttle(callback => callback(), SHOW_ALERT_INTERVAL),
      hasNewAppVersion: false,
      hasNewViewVersion: false,
      showAlert: false,
      interval: null,
    };
  },
  watch: {
    hasNewAppVersion() {
      this.changeShowAlertThrottledCallback(() => {
        this.showAlert = (this.showAlert && this.hasNewViewVersion) || this.hasNewAppVersion;
      });
    },
    currentViewChangedAt(value) {
      // use hasNewViewVersion to keep showAlert state when hasNewAppVersion is changed to false
      this.hasNewViewVersion = Boolean(value);

      if (!this.hasNewViewVersion && !this.hasNewAppVersion) {
        this.showAlert = false;
      } else {
        this.changeShowAlertThrottledCallback(() => {
          this.showAlert = (this.showAlert && this.hasNewAppVersion) || this.hasNewViewVersion;
        });
      }
    },
    async showAlert(value) {
      await nextTick();

      if (value) {
        const offset = getElementEdgeDistancesToViewport(this.$refs.newVersionAlert.$el?.children?.[0]).topToEnd;

        setRootCssProp('intercom-offset-element-top-to-viewport-end', `${offset}px`);
      } else {
        this.hasNewAppVersion = false;
        clearRootCssProp('intercom-offset-element-top-to-viewport-end');
      }
    },
  },
  mounted() {
    if (this.$config.public.version === 'local') return;

    this.interval = setInterval(this.fetchVersion, 30000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
    this.interval = null;
    clearRootCssProp('intercom-offset-element-top-to-viewport-end');
  },
  methods: {
    reload() {
      window.location.reload();
    },
    async fetchVersion() {
      fetch('/version.json').then(async (res) => {
        const result = await res.json();
        const version = result?.version || 'local';

        if (version === 'local') {
          clearInterval(this.interval);
          return;
        }

        if (version !== this.$config.public.version) {
          this.hasNewAppVersion = true;
          clearInterval(this.interval);
        }
      }).catch(() => {});
    },
  },
};
</script>
